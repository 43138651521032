var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simple"},[_c('b-form',[_c('b-card-code',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"title","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" العنوان ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"date","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v("تاريخ البداية ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.start_date)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"date","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v("تاريخ النهاية ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.end_date)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12","xl":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"title","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" النص ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.body)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"notes","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" ملاحظة")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.notes)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" الصورة ")]),_c('b-img',{ref:"previewEl",staticClass:"rounded mr-2 mb-1 mb-md-0 col-12",attrs:{"src":_vm.img,"height":"110","width":"170"}})],1)]),_c('b-col',{attrs:{"md":"1","xl":"3"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }